export const TOKEN = 'token'
export const CANDIDATE_TOKEN = 'candidate_token'
export const REFRESH_TOKEN = 'refresh_token'
export const EMPLOYEE_TOKEN = 'employee_token'

export type TLocalStorageKey =
  | typeof TOKEN
  | typeof REFRESH_TOKEN
  | typeof CANDIDATE_TOKEN
  | typeof EMPLOYEE_TOKEN
