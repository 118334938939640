import { axios } from 'helpers/hooks/useAxios/axios'
import { ENDPOINTS } from '../helpers/utils/endpoints'
import {
  GRANT_TYPE,
  RTGetLoginToken,
  RTRequestResetPassword,
  RTResetPassword,
  RTResetToken,
  RTValidateCandidateToken,
  TRequestResetPassword,
  TResetPassword,
  TResetToken,
} from './auth.types'

const requestResetPassword = async ({
  email,
}: TRequestResetPassword): Promise<RTRequestResetPassword> => {
  try {
    const response = await axios.post(ENDPOINTS.USER.REQUEST_RESET_PASSWORD, {
      email,
    })
    const status = response.status
    return { status }
  } catch (error) {
    const status = error.response.status
    return { status }
  }
}

const validateResetToken = async (
  token: TResetToken
): Promise<RTResetToken> => {
  try {
    const response = await axios.get(ENDPOINTS.USER.VALIDATE_RESET_TOKEN, {
      params: { token },
    })
    const status = response.status
    if (status === 204) return true
  } catch (error) {
    return false
  }
}

const resetPassword = async ({
  newPassword,
  token,
}: TResetPassword): Promise<RTResetPassword> => {
  try {
    const response = await axios.post(ENDPOINTS.USER.RESET_PASSWORD, {
      newPassword,
      token,
    })
    const status = response.status
    return { status }
  } catch (error) {
    const status = error.response.status
    return { status }
  }
}

const validateToken = async (
  token: string,
  grantType: GRANT_TYPE
): Promise<RTValidateCandidateToken> => {
  try {
    const tokenType = {
      [GRANT_TYPE.CANDIDATE_TOKEN]: 'candidateToken',
      [GRANT_TYPE.EMPLOYEE_TOKEN]: 'employeeToken',
      [GRANT_TYPE.PASSWORD]: 'password',
      [GRANT_TYPE.REFRESH_TOKEN]: 'refreshToken',
      [GRANT_TYPE.SHAREABLE_TOKEN]: 'shareableTokenId',
      [GRANT_TYPE.PUBLIC_CANDIDATE_TOKEN]: 'candidateId',
    }

    const response = await axios.post(ENDPOINTS.USER.LOG_IN, {
      grantType,
      [tokenType[grantType]]: token
    })
    const accessToken: string = response.data.accessToken
    const refreshToken: string = response.data?.refreshToken
    const status = response.status

    return { accessToken, status, refreshToken }
  } catch (error) {
    const status = error.response.status
    return { status }
  }
}

export const getLogInToken = async (password: string, username: string): Promise<RTGetLoginToken> => {
  const response = await axios.post(ENDPOINTS.USER.LOG_IN, {
    grantType: GRANT_TYPE.PASSWORD,
    password,
    username,
  })
  const accessToken = response?.data?.accessToken
  const refreshToken = response?.data?.refreshToken || ''
  const status = response.status

  return {accessToken, refreshToken, status}
}

export const auth = {
  requestResetPassword,
  resetPassword,
  validateResetToken,
  validateToken,
  getLogInToken
}
