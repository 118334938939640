import { axios } from 'helpers/hooks/useAxios/axios'
import { RTAxiosDefault } from 'helpers/hooks/useAxios/useAxios.types'
import { ENDPOINTS } from 'helpers/utils/endpoints'
import {
  ORGANISATION_PRODUCTS,
  RTGetCustomerPortalUrlData,
  RTOrganisationInfo,
  TGetCustomerPortalUrl,
  TOrganisation,
} from './organisation.types'

const info = async (
  organisationId: TOrganisation['id'],
  bearerToken: string
): Promise<RTOrganisationInfo> => {
  try {
    const { status, data } = await axios.get(
      ENDPOINTS.ORGANIZATION.ROOT(organisationId),
      {
        headers: {
          Authorization: bearerToken,
        },
      }
    )
    return { status, data }
  } catch (error) {
    const status = error.response.status
    return { status }
  }
}

const requestProductAccess = async (
  organisationId: TOrganisation['id'],
  product: ORGANISATION_PRODUCTS,
  bearerToken: string
): Promise<RTAxiosDefault> => {
  try {
    const { status } = await axios.post(
      ENDPOINTS.ORGANIZATION.REQUEST_PRODUCT_ACCESS(organisationId),
      { product },
      {
        headers: {
          Authorization: bearerToken,
        },
      }
    )
    return { status }
  } catch (error) {
    const status = error.response.status
    return { status }
  }
}

const getCustomerPortalUrl = async ({
  organisationId,
  token,
}: TGetCustomerPortalUrl): Promise<RTGetCustomerPortalUrlData> => {
  try {
    const response = await axios.get(
      ENDPOINTS.ORGANIZATION.CUSTOMER_PORTAL(organisationId),
      {
        headers: {
          Authorization: token,
          Accept: 'application/json',
          'content-type': 'application/json',
        },
      }
    )

    const { status, data } = response

    return { status, data }
  } catch (error) {
    const status = error.response.status
    return { status, data: null }
  }
}

export const organisation_api = {
  info,
  requestProductAccess,
  getCustomerPortalUrl,
}
