import { auth, getLogInToken } from 'api/auth'
import { axios } from '../useAxios/axios'
import {
  CANDIDATE_TOKEN,
  EMPLOYEE_TOKEN,
  REFRESH_TOKEN,
  TOKEN,
} from '../useLocalStorage/keys'
import { createContext, FC, useContext, useEffect, useState } from 'react'
import { ENDPOINTS } from 'helpers/utils/endpoints'
import { organisation_api } from 'api/organisation'
import { PREVIOUS_URL } from '../useSessionStorage/keys'
import { profile_api } from 'api/profile'
import {
  GRANT_TYPE,
  RTAuth,
  TAuth,
  TRefreshTokenValidationData,
} from 'api/auth.types'
import { TCandidateData } from 'api/candidate.types'
import { useLocalStorage } from '../useLocalStorage/useLocalStorage'
import { useRouter } from 'next/router'
import { useSessionStorage } from '../useSessionStorage/useSessionStorage'
import { TEmployeeData } from 'api/employees.types'
import { employee_profile_api } from 'api/employeeProfile'
import {
  CANDIDATE_JOURNEY_ROUTES,
  EMPLOYEE_JOURNEY_ROUTES,
  ROUTE,
  PUBLIC_ROUTES,
} from 'helpers/utils/routes'
import { RTEmployerAuthInfo } from './useAuth.types'
import { captureException } from '@sentry/nextjs'
import { segmentIdentification } from 'helpers/utils/analytics/analytics'
import { TOrganisation } from 'api/organisation.types'

const initialAuthContext = {
  organisationAllowAon: undefined,
  candidateToken: '',
  clearRefreshToken: () => null,
  clearToken: () => null,
  email: '',
  employeeToken: '',
  firstName: '',
  id: '',
  initials: '',
  isLoadingEmployer: true,
  isUserLoggedIn: false,
  lastName: '',
  logIn: ({ username, password }: TAuth): Promise<RTAuth> => {
    username && password
    return Promise.resolve({ status: 400, userData: null })
  },
  getEmployer: (token: string): Promise<RTEmployerAuthInfo> => {
    token
    return Promise.resolve({
      status: 400,
      userData: null,
      organisationData: null,
    })
  },
  addSignUpToken: (token: string, organisationId: string) => {
    token && organisationId
  },
  logOut: () => null,
  organisationId: '',
  organisationName: '',
  organisationProducts: [],
  organisationTimezone: '',
  organisationAssessmentsSource: undefined,
  refreshToken: '',
  role: '',
  setCandidateToken: (_nextToken: string) => null,
  setEmployeeToken: (_nextToken: string) => null,
  setProfileId: (_profileId: string | ((prevVal: string) => string)): void =>
    undefined,
  token: '',
  updateTokenWithRefreshToken: (
    _redirectionData?: TRefreshTokenValidationData
  ) => null,
  userData: undefined,
}

const AuthContext = createContext(initialAuthContext)

export const AuthProvider: FC = ({ children }) => {
  const router = useRouter()
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [id, setId] = useState('')
  const [initials, setInitials] = useState('')
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false)
  const [isLoadingEmployer, setIsLoadingEmployer] = useState(true)
  const [lastName, setLastName] = useState('')
  const [organisationId, setOrganisationId] = useState('')
  const [organisationName, setOrganisationName] = useState('')
  const [organisationTimezone, setOrganisationTimezone] = useState('')
  const [organisationProducts, setOrganisationProducts] = useState([])
  const [organisationAssessmentsSource, setOrganisationAssessmentsSource] =
    useState<TOrganisation['assessmentsSource']>(undefined)
  const [organisationAllowAon, setOrganisationAllowAon] =
    useState<TOrganisation['allowAon']>(undefined)
  const [userData, setUserData] = useState(undefined)
  const [profileId, setProfileId] = useState<TCandidateData['profileId']>('')
  const [role, setRol] = useState('')
  const [token, setToken, clearToken] = useLocalStorage(TOKEN, '')
  const [refreshToken, setRefreshToken, clearRefreshToken] = useLocalStorage(
    REFRESH_TOKEN,
    ''
  )
  const [candidateToken, setCandidateToken] = useLocalStorage(
    CANDIDATE_TOKEN,
    ''
  )
  const [employeeToken, setEmployeeToken] = useLocalStorage(EMPLOYEE_TOKEN, '')
  const [, , clearPreviousURL] = useSessionStorage(PREVIOUS_URL)

  const queryToken = router.query?.token as string

  const getInitials = (name: string, lastName: string) =>
    `${name[0]}${lastName[0]}`.toUpperCase()

  const getEmployee = async (
    profileId: TEmployeeData['employeeProfileId'],
    employeeQueryToken: TEmployeeData['token'],
    employeeToken: string
  ) => {
    try {
      const tokenResponse =
        employeeQueryToken &&
        (await auth.validateToken(
          employeeQueryToken,
          GRANT_TYPE.EMPLOYEE_TOKEN
        ))

      const response = await employee_profile_api.info(
        profileId,
        employeeToken ? employeeToken : `Bearer ${tokenResponse.accessToken}`
      )
      if (response.status === 200) {
        const initials = getInitials(response.data.name, response.data.lastName)
        setFirstName(response.data.name)
        setLastName(response.data.lastName)
        setInitials(initials)
      }
    } catch (error) {
      const status = error.response.status
      return { status }
    }
  }

  const getCandidate = async (
    profileId: TCandidateData['profileId'],
    candidateQueryToken: TCandidateData['token'],
    candidateToken: string
  ) => {
    try {
      const authResponse =
        candidateQueryToken &&
        (await auth.validateToken(
          candidateQueryToken,
          GRANT_TYPE.CANDIDATE_TOKEN
        ))

      const response = await profile_api.info(
        profileId,
        candidateToken ? candidateToken : `Bearer ${authResponse.accessToken}`
      )
      if (response.status === 200) {
        const initials = getInitials(response.data.name, response.data.lastName)
        setFirstName(response.data.name)
        setLastName(response.data.lastName)
        setInitials(initials)
      }
    } catch (error) {
      const status = error.response.status
      return { status }
    }
  }

  const getEmployer = async (
    bearerToken: string = token
  ): Promise<RTEmployerAuthInfo> => {
    setIsLoadingEmployer(true)
    try {
      const { data, status } = await axios.get(ENDPOINTS.USER.ME, {
        headers: {
          Authorization: bearerToken,
          Accept: 'application/json',
          'content-type': 'application/json',
        },
      })
      const { data: organisationInfo, status: organisationStatus } =
        await organisation_api.info(data.organisationId, bearerToken)
      const initials = getInitials(data.firstName, data.lastName)
      setIsUserLoggedIn(true)
      setEmail(data.email)
      setFirstName(data.firstName)
      setId(data.id)
      setInitials(initials)
      setLastName(data.lastName)
      setOrganisationId(data.organisationId)
      setOrganisationName(organisationInfo.name)
      setUserData(data.userData)
      setOrganisationTimezone(organisationInfo.timezone)
      setOrganisationProducts(organisationInfo.products)
      setOrganisationAssessmentsSource(organisationInfo.assessmentsSource)
      setOrganisationAllowAon(organisationInfo.allowAon)
      setRol(data.role)

      if (status === 200 && organisationStatus === 200) {
        return { userData: data, status, organisationData: organisationInfo }
      }
    } catch (error) {
      const status = error.response?.status
      if (error.response?.status === 401 && refreshToken) {
        await updateTokenWithRefreshToken()
      } else {
        logOut()
        return { status }
      }
    } finally {
      setIsLoadingEmployer(false)
    }
  }

  const addSignUpToken = async (token: string, organisationId: string) => {
    try {
      clearToken()
      clearRefreshToken()
      const bearerToken = `Bearer ${token}`
      setToken(bearerToken)

      const {
        status = null,
        userData = null,
        organisationData = null,
      } = await getEmployer(bearerToken)

      if (status === 200) segmentIdentification(userData, organisationData)
    } catch (error) {
      captureException(error, { extra: { organisationId } })
    }
  }

  const logIn = async ({
    username,
    rememberMe = false,
    password,
  }: TAuth): Promise<RTAuth> => {
    try {
      const { accessToken, status, refreshToken } = await getLogInToken(
        password,
        username
      )
      const bearerToken = `Bearer ${accessToken}`
      await setToken(bearerToken)
      if (rememberMe) setRefreshToken(refreshToken)
      const {
        status: employerStatus = null,
        userData = null,
        organisationData = null,
      } = await getEmployer(bearerToken)

      if (employerStatus === 200)
        segmentIdentification(userData, organisationData)

      return { status }
    } catch (error) {
      const status = error.response.status
      return { status }
    }
  }

  const logOut = () => {
    global.analytics.reset()
    setIsUserLoggedIn(initialAuthContext.isUserLoggedIn)
    setEmail(initialAuthContext.email)
    setFirstName(initialAuthContext.firstName)
    setId(initialAuthContext.id)
    setInitials(initialAuthContext.initials)
    setLastName(initialAuthContext.lastName)
    setOrganisationAssessmentsSource(
      initialAuthContext.organisationAssessmentsSource
    )
    setOrganisationId(initialAuthContext.organisationId)
    setOrganisationProducts(initialAuthContext.organisationProducts)
    setRol(initialAuthContext.role)
    setOrganisationTimezone(initialAuthContext.organisationTimezone)
    clearToken()
    clearRefreshToken()
    clearPreviousURL()
    router.push('/')
  }

  // TODO: refactor and improve error handling
  const updateTokenWithRefreshToken = async (
    validationData?: TRefreshTokenValidationData
  ) => {
    const tokenToValidate = validationData
      ? validationData.refreshToken
      : refreshToken

    const response = await auth.validateToken(
      tokenToValidate,
      GRANT_TYPE.REFRESH_TOKEN
    )
    if (response.status === 200) {
      const bearerToken = `Bearer ${response.accessToken}`
      setToken(bearerToken)
      setRefreshToken(response.refreshToken)

      if (validationData) {
        const {
          status: employerStatus = null,
          userData = null,
          organisationData = null,
        } = await getEmployer(bearerToken)

        if (employerStatus === 200)
          segmentIdentification(userData, organisationData)

        router.push(validationData.redirectUrl)
      } else {
        //TODO: repeat request instead of reload
        router.reload()
      }
    } else {
      captureException('Failed refreshToken validation', {
        extra: { organisationId, refreshToken },
      })
      logOut()
    }
  }

  // TODO: add route validation too
  useEffect(() => {
    if (PUBLIC_ROUTES.includes(router.pathname)) return

    if (
      (token && !(candidateToken || employeeToken || queryToken)) ||
      router.pathname === ROUTE.HOME
    ) {
      getEmployer()
    } else if (
      (queryToken || candidateToken) &&
      profileId &&
      CANDIDATE_JOURNEY_ROUTES.includes(router.pathname)
    ) {
      getCandidate(profileId, queryToken, candidateToken)
    } else if (
      (queryToken || employeeToken) &&
      profileId &&
      EMPLOYEE_JOURNEY_ROUTES.includes(router.pathname)
    ) {
      getEmployee(profileId, queryToken, employeeToken)
    }
  }, [profileId])

  return (
    <AuthContext.Provider
      value={{
        organisationAllowAon,
        candidateToken,
        clearRefreshToken,
        clearToken,
        email,
        employeeToken,
        firstName,
        id,
        initials,
        isLoadingEmployer,
        isUserLoggedIn,
        lastName,
        userData,
        logIn,
        logOut,
        organisationAssessmentsSource,
        organisationId,
        organisationName,
        organisationProducts,
        organisationTimezone,
        refreshToken,
        role,
        setCandidateToken,
        setEmployeeToken,
        setProfileId,
        token,
        updateTokenWithRefreshToken,
        addSignUpToken,
        getEmployer,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
