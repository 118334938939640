import { css, CSSProp } from 'styled-components'

type TFlexDirection =
  | 'row'
  | 'row-reverse'
  | 'column'
  | 'column-reverse'
  | 'inherit'
  | 'initial'
  | 'unset'

type TAlignItems =
  | 'stretch'
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'baseline'
  | 'start'
  | 'end'
  | 'self-start'
  | 'self-end'

type TJustifyContent =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'space-evenly'
  | 'start'
  | 'end'
  | 'left'
  | 'right'

type TFlexWrap = 'wrap' | 'nowrap'

export const flex = (
  flexDirection: TFlexDirection = 'row',
  alignItems: TAlignItems = 'center',
  justifyContent: TJustifyContent = 'center',
  flexWrap: TFlexWrap = 'nowrap'
): CSSProp => css`
  display: flex;
  flex-direction: ${flexDirection};
  align-items: ${alignItems};
  justify-content: ${justifyContent};
  flex-wrap: ${flexWrap};
`
