export const color = {
  alignment: {
    normalized: '#2c3f58',
    risk: '#FF9E44',
  },
  background: '#F3F5F9',
  black: '#2c3f58',
  blackDark: '#161A1F',
  blackLight: '#f0f4f8',
  blueDark: '#35425b',
  blueLight: '#6b95c1',
  blueLightTransparency: '#02A3CF33',
  blueTransparency: '#619BBF1A',
  blueSuperLight: '#cce5ff',
  blueishGreen: '#6BC1C1',
  college: {
    black: '#1A2026',
    green: '#6ACAAB',
    grey: '#364355',
    greyDark: '#232A33',
  },
  disabled: '#d7e1ea',
  error: '#ff4961',
  success: '#54D393',
  fit: {
    excellent: '#54D393',
    good: '#66C6E1',
    moderate: '#7C8AC4',
    pending: '#9E9E9E',
    poor: '#FF9E44',
  },
  fitIA: {
    poor: '#ff4961',
    moderate: '#FBC02D',
    great: '#54D393',
  },
  gold: '#f1d554',
  gradient: 'linear-gradient(270deg, #5AD1D8 0%, #4DAEC1 100%);',
  gradient2:
    'linear-gradient(270deg, rgba(0, 240, 198, 0.70) 0%, rgba(150, 165, 230, 0.70) 92.85%)',
  gradient3:
    'linear-gradient(270deg, rgba(90, 209, 216, 0.70) 0%, rgba(161, 204, 159, 0.70) 50%, rgba(255, 198, 83, 0.70) 100%)',
  gradient4: 'linear-gradient(90deg, #02a3cf 0%, #7c8ac4 100%);',
  gradient5: 'linear-gradient(270deg, #7C8AC4 20.67%, #2C3F58 100%)',
  great: '#54D393',
  greenDark: '#1DA761',
  greenLight: '#98e5be',
  greenLighter: '#ccf2df',
  greenTransparency: '#00D3AE1A',
  grey: '#778699',
  greyLight: '#f5f5f5',
  greyMid: '#A8B1C1',
  greySuperLight: '#e0e0e0',
  greyTransparency: '#ffffff66',
  lavender: '#7c8ac4',
  pass: '#00d3ae',
  personality: '#6bc1c1',
  pollination: {
    lightBlue: '#F2F2FF',
    blue: '#5381FF',
  },
  popBlue: '#02A3CF',
  popGreen: '#00D3AE',
  popOrange: '#FFC653',
  primary: '#5ad1d8',
  secondary: '#4daec1',
  veryLightGreen: '#8BD2D2',
  veryLightGreenTransparency: '#8BD2D240',
  warning: '#FBC02D',
  white: '#ffffff',
  whiteDark: '#fafafa',
  whiteLight: '#FAFBFD',
  redLight: '#ffedef',
  reports: {
    black: '#232A33',
    card: '#1A2026',
    divider: '#393D4A',
    grey: '#79859B',
    green: '#54D393',
    greySuperLight: '#E0E6E7',
    lavender: '#DCE0EF',
    orange: '#FE8F51',
    skyBlue: '#EBF4F5',
    teal: '#00C3CD',
    whiteDark: '#D9D9D9',
    yellow: '#FFC653',
  },
  yellowTransparency: '#FFC6531A',
}
