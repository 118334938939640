export const font = {
  family: {
    primary: 'Circular Std',
    report: 'IBM Plex Sans',
  },
  size: {
    xxs: '10px',
    xs: '12px',
    s: '14px',
    m: '16px',
    l: '18px',
    xl: '24px',
    xxl: '36px',
    xxxl: '64px',
  },
}
