import { TOrganisation } from 'api/organisation.types'
import { RTMe } from 'api/user.types'
import { RTAxiosDefault } from '../useAxios/useAxios.types'

export enum TAuthType {
  EMPLOYER = 'employer',
  CANDIDATE = 'candidate',
  PUBLIC = 'public',
  EMPLOYEE = 'employee',
  REPORT = 'report',
  UNREGISTERED_USER = 'unregistered_user'
}

export interface RTEmployerAuthInfo extends RTAxiosDefault {
  userData?: RTMe
  organisationData?: TOrganisation
}
